import React, { Component } from "react";
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import HeaderLeftLinks from "../components/Header/HeaderLeftLinks";
import { Color as Colors } from "../src/utils/Color";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomFont from "../components/CustomFont";
import Footer from "../components/Footer/Footer";
import Button from "@mui/material/Button";
import Router from "next/router";
import Image from "next/image";

class NotFound extends Component {
  onClickHome = () => {
    Router.push("/");
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
          background: "white"
        }}
      >
        <Header
          ref={this.headerRef}
          key="404"
          brand={true}
          rightLinks={<HeaderLinks />}
          leftLinks={<HeaderLeftLinks />}
          fixed
          color="white"
          style={{
            marginBottom: 0,
            boxShadow: "none",
            borderBottom: `1px solid ${Colors.borderColor}`
          }}
        />
        <Header
          ref={this.headerRef}
          key="404"
          brand={true}
          rightLinks={<HeaderLinks />}
          leftLinks={<HeaderLeftLinks />}
          color="white"
          style={{
            marginBottom: 0,
            boxShadow: "none",
            position: "unset",
            zIndex: 1,
              visibility:"hidden"
          }}
        />
        <Container style={{ display: "flex", flex: 1 }}>
          <Grid container spacing={5} style={{ marginTop: "3rem" }}>
            <Grid
              item
              md={6}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                flex: 1,
                alignItems: "flex-start"
              }}
            >
              <CustomFont size="xalarge" styles={{ fontWeight: "600" }}>
                {`WE"RE SO SORRY, PAGE NOT FOUND!`}
              </CustomFont>
              <CustomFont styles={{ marginTop: "20px" }}>
                {`We're sorry, the page you requested could not be found. Please go back to the homepage.`}
              </CustomFont>
              <Button
                onClick={this.onClickHome}
                color="primary"
                variant="contained"
                style={{ fontWeight: "600", marginTop: "20px" }}
              >
                Go To Homepage
              </Button>
            </Grid>
            <Grid item md={6} xs={12} >
                <div style={{position:"relative",height:"100%",width:"100%",minWidth:"400px",minHeight:"400px"}}><Image src='/not-found-404.png' objectFit="contain" layout="fill"/>
                </div>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default NotFound;
